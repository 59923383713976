import { useCallback, useMemo } from 'react';
import config from '~/config';
import type { DataLayerEventNames } from '~/types/analytics';
import { pushToDataLayer } from '~/utils/analytics';
import { ansi } from '~/utils/ansiColors';
import { useMeOptional } from './useMe';

export default function useEventTracker(debug = false) {
  const { me } = useMeOptional();

  const myUtm = useMemo(() => {
    if (!me || me.__typename === 'Operator' || me.__typename === 'PartnerAgent' || !me.utm) {
      return {};
    }
    return {
      ...(me.utm.source ? { utm_source: me.utm.source } : {}),
      ...(me.utm.medium ? { utm_medium: me.utm.medium } : {}),
      ...(me.utm.campaign ? { utm_campaign: me.utm.campaign } : {}),
      ...(me.utm.term ? { utm_term: me.utm.term } : {}),
      ...(me.utm.content ? { utm_content: me.utm.content } : {}),
    };
  }, [me]);

  const handleEvent = useCallback(
    async (event: DataLayerEventNames, params: Record<string, any>, includeUtm = true) => {
      let extendedParams = params;
      if (includeUtm) extendedParams = { ...myUtm, ...params }; // Overwrite me.utm params if passed along

      if (!config.isProduction && debug) {
        // eslint-disable-next-line prettier/prettier
        console.log(ansi.lightGreen, '📣 pushing event to DataLayer:', event, '\nEvent parameters:');
        console.table({
          event,
          ...extendedParams,
          user_id: params.user_id || me?.id,
          user_type: params.user_type || me?.__typename,
        });
      }

      try {
        pushToDataLayer({ user_id: me?.id, user_type: me?.__typename, event, ...extendedParams });
      } catch (e) {
        console.error(e);
      }
    },
    [debug, me?.__typename, me?.id, myUtm],
  );
  return handleEvent;
}
