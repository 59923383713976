import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

// Configure DayJS for both server and client
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale('nl');

/** Whether the current process is running on the server or on the client */
const isServer = typeof window === 'undefined';

export type PdfPath =
  | 'quote'
  | 'advice'
  | 'advice-gva'
  | 'results'
  | 'results-ebv'
  | 'impact-results'
  | 'extract'
  | 'gva-scan';

type Environment = 'development' | 'staging' | 'production';

export interface IConfig {
  /**
   * Checks whether all configuration options are present, logging any missing configuration options
   * that do not have a default value.
   */
  validate(): void;
}

export class APIKeyConfig implements IConfig {
  constructor(private readonly config: Config) {}

  dato = {
    /** if defined, this specifies the DatoCMS environment to fetch data from */ // TODO (maybe): find a suitable default, if needed.
    environment: process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT,
    /**
     * The preview secret is a secret shared only by DatoCMS and the NextJS API handler that resolves preview pages for DatoCMS content,
     * so as to prevent unauthorized access to preview content.
     * NOTE: if this secret is left undefined, then preview routes will not work.
     */
    previewSecret: process.env.DATOCMS_PREVIEW_SECRET,
    /** this should be a read-only DatoCMS API token with access to DatoCMS' GraphQL API */
    token: process.env.NEXT_PUBLIC_DATOCMS_TOKEN || 'd50db6a1aa9ea90e5e4d17875c9ab3',
  };

  google = {
    /**
     * Google Maps / StreetView API key. These are used in URLs shown to the user and the API keys
     * are (supposed to be) protected so that they cannot be used outside of energiebespaarders.nl
     * or staging.energiebespaarders.nl.
     * Because of that, they're not really secrets and can be kept hardcoded in here.
     */
    maps: this.config.isProduction
      ? 'AIzaSyCsJIuZLHgZyp3Y7760HVZd4Kg40upNFKk'
      : 'AIzaSyB3cPBJYrx3LSpXoCJBaUckRWlzoeujldM',

    /** Google Tag Manager Container ID. There's a separate GTM ID for the server-side container, but that's managed in [TAGGRS](taggrs.io) */
    tagManager: 'GTM-N3V7DWH', // Client-side container
  };

  gcp = {
    PROJECT_ID: process.env.GCP_PROJECT_ID || 'deb-platform',
    CLIENT_ID: process.env.GCP_CLIENT_ID || '',
    CLIENT_SECRET: process.env.GCP_CLIENT_SECRET || '',
    ENERGY_LABEL_BUCKET_NAME: process.env.GCP_ENERGY_LABEL_BUCKET_NAME || 'energy-labels-stg',
  };

  intercom = {
    /** Intercom workspace ID */
    appId: 'pvedjmj7',
  };

  logrocket = {
    /** ID of our LogRocket setup */
    id: 'vioyzp',
    /** App ID of Chili on LogRocket */
    appId: 'vioyzp/de-energiebespaarders',
  };

  sentry = {
    /** Sentry Data Source Name (DSN). A DSN tells a Sentry SDK where to send events so the events are associated with the correct project. */
    dsn:
      process.env.SENTRY_DSN ||
      process.env.NEXT_PUBLIC_SENTRY_DSN ||
      // Note: this default value was found hardcoded in src/components/_app/initializeSession.ts and in .env.example
      'https://3c72cd9394674635963654155989fa18@sentry.io/176594',
    /** Sentry environment */
    environment: this.config.isProduction
      ? 'production'
      : this.config.isStaging
      ? 'staging'
      : 'development',
  };

  validate(): void {
    if (isServer && !this.dato.previewSecret)
      console.warn(
        'Warning! DatoCMS preview routes are disabled because the DATOCMS_PREVIEW_SECRET environment variable is not set',
      );
  }
}

export class URLConfig implements IConfig {
  constructor(private readonly config: Config) {}

  /** the base URL on which Chili is hosted */
  base = this.defaultBaseURL;
  /** the URL pointing to the graphql-api deployment */
  api = this.defaultApiURL;
  /** the URL to access our GraphQL API */
  graphql = this.api + '/graphql';
  /** the URL pointing to an endpoint on graphql-api used for downloading files from Fennel */
  files = this.api + '/files';
  /** the URL pointing to an endpoint on graphql-api used for downloading a list of files from Fennel as a .zip file */
  zip = this.api + '/zip';

  /** the URL of our Google Cloud Storage bucket containing a bunch of images */
  bucket = 'https://storage.googleapis.com/deb-documents';

  images = {
    /** URL pointing to a folder within our GCS bucket for images of advisers' signatures */
    adviserSignatures: this.bucket + '/humulus/adviser-signatures',
    /** URL pointing to a folder within our GCS bucket with images for Humulus (PDF generation). Also contains adviser profile pictures in the old format */
    humulus: this.bucket + '/humulus',
    /** URL pointing to a folder within our GCS bucket with product images */
    products: this.bucket + '/products',
  };

  get defaultBaseURL(): string {
    if (this.config.isProduction) return 'https://energiebespaarders.nl';
    if (this.config.isStaging) return 'https://staging.energiebespaarders.nl';
    return 'http://localhost:3000';
  }

  get defaultApiURL(): string {
    // The client uses the NextJS server to make API calls (see pages/api/graphql)
    if (typeof window !== 'undefined') {
      return '/api';
    }
    // The server connects to the graphql-api server
    if (this.config.isProduction) return 'https://api.energiebespaarders.nl/api/v2';
    if (this.config.isStaging) return 'https://api-staging.energiebespaarders.nl/api/v2';
    return 'http://localhost:4000';
  }

  /**
   * Creates a URL to download a PDF, either through graphql-api (production & staging)
   * or directly through Humulus (local development)
   * TODO: similar functions might be useful for getting API URLs, file URLs, zip URLs, Street View URLS and possibly bucket URLs
   */
  getPdfURL(path: PdfPath, key: string, value: string, otherParams?: string): string {
    if (this.config.isDeveloping) {
      const params = `?client=${this.config.clientName}&${key}=${value}${otherParams || ''}`;
      return `http://localhost:3001/${path}${params}`;
    }
    return `${this.api}/pdf/${path}/${value}?client=${this.config.clientName}${otherParams || ''}`;
  }

  validate(): void {
    // nothing to validate, everything is already specified by default.
  }
}

export class BuildConfig implements IConfig {
  /** When enabled, pages with statically generated paths should return no paths. Intended to speed up build-time with the build:fast command */
  skipStaticGeneration = process.env.SKIP_BUILD_STATIC_GENERATION === 'true';

  constructor(private readonly config: Config) {}

  validate(): void {
    // nothing to validate, everything is already specified by default.
  }
}

export class Config implements IConfig {
  /** Version is set by Webpack when building NextJS */
  version = process.env.VERSION || '1.0.0';
  clientName = 'chili';

  /** Can be used to check whether the current render is in the pre-render phase (during build-time) */
  isPrerendering = process.env.npm_lifecycle_event === 'build';

  /**
   * HostnameEnvs is a mapping of hostnames to environment names.
   * This is used to determine the environment based on the hostname
   * without having to call the api/env endpoint in the browser.
   */
  hostnameEnvs = {
    'energiebespaarders.nl': 'production',
    'staging.energiebespaarders.nl': 'staging',
    localhost: this.isPrerendering ? 'development' : undefined,
  } as Record<string, Environment>;

  environment = process.env.ENV || (!isServer && this.hostnameEnvs[location.hostname]) || '';

  isDeveloping = this.environment === 'development';
  isStaging = this.environment === 'staging';
  isProduction = this.environment === 'production';

  keys = new APIKeyConfig(this);
  urls = new URLConfig(this);
  build = new BuildConfig(this);

  unleash = {
    host: 'https://gitlab.com/api/v4/feature_flags/unleash/1936255',
    instanceId: 'EKcUVJS3iMgvDbP2iXfU',
    appName: this.isProduction ? 'production' : 'staging',
  };

  validate(): void {
    this.keys.validate();
    this.urls.validate();
    this.build.validate();
  }

  reinitializeWithEnvironment(environment: Environment) {
    this.environment = environment;
    this.isDeveloping = environment === 'development';
    this.isStaging = environment === 'staging';
    this.isProduction = environment === 'production';

    this.keys = new APIKeyConfig(this);
    this.urls = new URLConfig(this);
    this.build = new BuildConfig(this);
  }
}

export default new Config();
